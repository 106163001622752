<template>
  <div>
    <span v-if="totalRows>0"> Showing {{ currentPageCnt }} of {{ totalRows }} </span>
  </div>
</template>
<script>
export default {
  name: 'RowCount',
  props: {
    totalRows: {
      type: Number,
      default: 0
    },
    rowPrePage: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    currentPageCnt () {
      const currentpageCnt = this.rowPrePage * this.currentPage
      if (currentpageCnt > this.totalRows) {
        const res = this.totalRows % this.rowPrePage
        if (res === 1) { return this.totalRows }
        return (this.totalRows - res + 1) + ' - ' + this.totalRows
      } else if (this.rowPrePage === 1) return this.currentPage
      return (currentpageCnt - this.rowPrePage + 1) + ' - ' + currentpageCnt
    }
  }
}
</script>
