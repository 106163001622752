const modelList = [
  {
    label: 'Prime L Series',
    options: [
      { value: 0, text: 'Prime 30L' },
      { value: 1, text: 'Prime 60L' }
    ]
  },
  {
    label: 'Prime B Series',
    options: [
      { value: 2, text: 'Prime 30B' },
      { value: 3, text: 'Prime 60B' }
    ]
  },
  {
    label: 'MA Series',
    options: [
      { value: 10, text: 'SA-230MA' },
      { value: 11, text: 'SA-230MA-R' },
      { value: 12, text: 'SA-260MA' },
      { value: 13, text: 'SA-260MA-R' },
      { value: 14, text: 'SA-300MA' },
      { value: 15, text: 'SA-300MA-R' },
      { value: 16, text: 'SA-300VMA' },
      { value: 17, text: 'SA-300VMA-R' }
    ]
  },
  {
    label: 'MA Series',
    options: [
      { value: 20, text: 'SA-260MB' },
      { value: 21, text: 'SA-300MB' },
      { value: 22, text: 'SA-302MB' }
    ]
  },
  {
    label: 'SA-382VMB',
    options: [
      { value: 30, text: 'SA-382VMB' }
    ]
  }
]

export default modelList
